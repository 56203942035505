import React, { useState } from 'react';
import reidea_icon from '../img/reidea-icon.png';
import { Stack, Drawer, IconButton, List, ListItem, ListItemText, AppBar, Toolbar, Link as SnsLink, useMediaQuery, useTheme, Theme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import XIcon from '../img/sns/x_iconSP.png';
import NoteIcon from '../img/sns/note-iconSP.png'
import FacebookIcon from '../img/sns/fb_iconSP.png';
import InstagramIcon from '../img/sns/ig_iconSP.png';


const style = (theme : Theme)  => ({
  color: '#383E86',
  fotnSize: {
    xs:'18px',
    sm: '20px',
    md: '22px'
  }
});

const HeaderSP = () => {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));

  const getIconSize = () => {
    if (isXs) return '35px';
    if (isSm) return '50px';
    if (isMd) return '70px';
    return '60px'; // デフォルトサイズ (lg以上)
  };

  return (
    <AppBar position="fixed" sx={{bgcolor:'white'}}>
      <Toolbar>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ width: '100%' }}>
          <RouterLink to="/">
            <img src={reidea_icon} width={'40%'} alt='img' />
          </RouterLink>
          <IconButton onClick={toggleDrawer(true)} color="inherit">
            <MenuIcon sx={{color:'gray'}}/>
          </IconButton>
        </Stack>
      </Toolbar>
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '80%',
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
      >
        <Stack direction="row" justifyContent="flex-end" alignItems="center" p={2}>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <List>
          <Link to="about" smooth={true} duration={500}  offset={-64}>
            <ListItem onClick={toggleDrawer(false)}>
              <ListItemText primary="About" sx={{...style(theme)}}/>
            </ListItem>
          </Link>
          <Link to="service" smooth={true} duration={500}  offset={-64}>
            <ListItem onClick={toggleDrawer(false)}>
              <ListItemText primary="Service" sx={{...style(theme)}}/>
            </ListItem>
          </Link>
          <Link to="merit" smooth={true} duration={500}  offset={-64}>
            <ListItem onClick={toggleDrawer(false)}>
              <ListItemText primary="Merit" sx={{...style(theme)}}/>
            </ListItem>
          </Link>
          
          <Link to="works" smooth={true} duration={500}  offset={-64}>
            <ListItem onClick={toggleDrawer(false)}>
              <ListItemText primary="Works" sx={{...style(theme)}}/>
            </ListItem>
          </Link>
          <Link to="contact" smooth={true} duration={500}  offset={-64}>
            <ListItem onClick={toggleDrawer(false)}>
              <ListItemText primary="Contact" sx={{...style(theme)}}/>
            </ListItem>
          </Link>
        </List>
        <Stack direction={'row'} mx={2} my={4} spacing={2} justifyContent={'center'}>
          <SnsLink href='http://www.facebook.com/share/WPmE2sgMqvUiL5Dg'>
              <img src={FacebookIcon} alt='img' width={getIconSize()}/>
          </SnsLink>
          <SnsLink href='http://www.instagram.com/re_idea.official'>
            <img src={InstagramIcon} alt='img' width={getIconSize()}/>

          </SnsLink>
          <SnsLink href='http://x.com/reidea_official'>
            <img src={XIcon} alt='img' width={getIconSize()}/>
          </SnsLink>
          <SnsLink href='https://note.com/re_idea'>
            <img src={NoteIcon} alt='img' width={getIconSize()}/>
          </SnsLink>
        </Stack>
      </Drawer>
    </AppBar>
  );
};

export default HeaderSP;