import React from 'react'
import { Stack, Typography, Theme, useTheme } from '@mui/material';
import { categoryStyle, categorySubStyle, mainTitleStyle, messageStyle } from '../components/style/style';


interface Props{
  number: string;
  title: string;
  content: string;
}
const numberStyle = (theme: Theme) => ({
  fontFamily: 'Noto Sans JP',
  fontWeight: 'bold', // 'medium'の代わりに数値で指定
  background: 'linear-gradient(to right, #43CEA2 0%, #185A9D 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  color: 'transparent', // Fallback for browsers that don't support background-clip: text
  display: 'inline-block', // グラデーションを適切に表示するために必要
  fontSize: {
    xs: '20px',  // エクストラスモール画面用
    sm: '24px',  // スモール画面用
    md: '32px',  // ミディアム画面用
  },
});

const titleStyle = (theme: Theme) => ({
  fontFamily: 'Noto Sans JP',
  fontWeight: 'medium',
  color: '#383E86',
  fontSize: {
    xs: '20px',  // エクストラスモール画面用
    sm: '24px',  // スモール画面用
    md: '32px',  // ミディアム画面用
  },
});

const contentStyle = (theme: Theme) => ({
  fontFamily: 'Noto Sans JP',
  fontWeight: 'medium',
  color: '#383E86',
  fontSize: {
    xs: '12px',  // エクストラスモール画面用
    sm: '16px',  // スモール画面用
    md: '20px',  // ミディアム画面用
  },
});

const MeritCarouselCard = ({number, title, content}:Props) => {
  const theme = useTheme();

  return (
    <Stack>
      <Typography sx={{...numberStyle(theme)}}>{number}</Typography>
      <Typography sx={{...titleStyle(theme)}}>{title}</Typography>
      <Typography sx={{...contentStyle(theme)}}>{content}</Typography>
    </Stack>
  )
}

export default MeritCarouselCard
