import React, { useState } from 'react'
import {Stack, Typography, ButtonBase, Button} from '@mui/material';
import CopyrightIcon from '@mui/icons-material/Copyright';

export const style = {
  fontFamily: 'Noto Sans JP',
  fontSize: {
    xs: '12px',
    sm: '13px',
    md: '14px'
  },
  fontWeight: 'auto',
  color: 'white',
};

export const logoStyle = {
  fontFamily: 'Noto Sans JP',
  fontSize: {
    xs: '20px',
    sm: '30px',
    md:'40px'
  },
  fontWeight: 'bold',
  color: 'white',
};

interface Props{
  isMobile: boolean;
}

const Footer = ({isMobile}:Props) => {
  const scrollRef = React.createRef<HTMLDivElement>()

  const scrollToTop = () => {
    if(scrollRef !== null && scrollRef?.current){
        const elementTop = scrollRef.current.getBoundingClientRect().top;
        window.scrollBy({ top: elementTop , behavior: 'smooth' });
    }else{
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    } 
  };


  return (
    <Stack bgcolor={'#383E86'} px={'2rem'} pt={{xs:'3rem', sm:'4rem', md:'5rem'}} pb={'2rem'} spacing={{xs:'0.5rem', sm:'1rem', md:'2rem'}}>
      <Typography sx={{...logoStyle}}>RE-IDEA</Typography>
        <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'space-between'}>
          <Stack>
            <Stack direction={'row'}>
              <CopyrightIcon fontSize='small' sx={{color: 'white'}}/>
              <Typography sx={{...style}}>2024 RE-IDEA. All rights reserbed.</Typography>
            </Stack>
            <Typography sx={{...style}}>お問い合わせ: reidea.official@gmail.com | 070-1436-0803（代表）</Typography>
          </Stack>
          <Button onClick={scrollToTop} >
            {!isMobile && <Typography sx={{...style}}>Back to top</Typography>
            
            }
          </Button>
      </Stack>
    </Stack>
  )
}

export default Footer
