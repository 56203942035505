import React from 'react'
import { categoryStyle, categorySubStyle, mainTitleStyle, messageStyle } from './style/style';
import {Box, Divider, Stack, Theme, Typography, useTheme} from '@mui/material';
import tmpPNG from '../img/works_tmple.png'

const contentTitleStyle = (theme: Theme)=> ({
  fontFamily: 'Noto Sans JP',
  fontSize: {
    xs: '20px',  // エクストラスモール画面用
    sm: '24px',  // スモール画面用
    md: '32px',  // ミディアム画面用
  },
  fontWeight: 'bold',
  color: '#383E86',
});

const contentMessageStyle  = (theme: Theme)=> ({
  fontFamily: 'Noto Sans JP',
  fontSize: {
    xs: '14px',  // エクストラスモール画面用
    sm: '16px',  // スモール画面用
    md: '18px',  // ミディアム画面用
    lg: '20px',  // ラージ画面用
  },
  fontWeight: 'medium',
  color: 'black',
});


const Works = () => {
  const theme = useTheme();

  return (
    <Box id='works' width={'100%'}>
      <Stack 
      spacing={{
        xs: '2rem',
        sm: '3rem',
        md: '5rem'
      }} alignItems={'center'}
      sx={{
        px: {
          xs: '1.5rem',
          sm: '5rem',
          md: '10rem'
        },
        py: {
          xs: '2rem',
          sm: '5rem',
          md: '8rem'
        }
      }}>
        <Stack alignItems={'center'}>
          <Typography sx={{...categoryStyle(theme), color:'#383E86'}}>WORKS</Typography>
          <Typography sx={{...categorySubStyle(theme), color:'#383E86'}}>成功事例のご紹介</Typography>
        </Stack>
        <Stack direction={'row'} spacing={{xs:'2rem', sm:'3rem', md: '6rem'}}alignItems={'flex-start'}>
          <img src={tmpPNG} alt='img' width={'50%'}/>
          <Stack width={'35%'} spacing={{xs:'0.5rem', sm:'1.5rem', md: '3rem'}} pt={{xs:'10%', sm: '15%', md:'20%'}}>
            <Typography sx={{...contentTitleStyle(theme)}}>日本初の伝統製品</Typography>
            <Typography sx={{...contentMessageStyle(theme)}}>ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー</Typography>
          </Stack>
        </Stack>

        <Stack direction={'row'} spacing={{xs:'2rem', sm:'3rem', md: '6rem'}} alignItems={'flex-start'}>
          <Stack width={'35%'} spacing={{xs:'0.5rem', sm:'1.5rem', md: '3rem'}} pt={{xs:'10%', sm: '15%', md:'20%'}}>
            <Typography sx={{...contentTitleStyle(theme)}}>日本初の伝統製品</Typography>
            <Typography sx={{...contentMessageStyle(theme)}}>ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー</Typography>
          </Stack>
          <img src={tmpPNG} alt='img' width={'50%'}/>
        </Stack>

        <Stack direction={'row'} spacing={{xs:'2rem', sm:'3rem', md: '6rem'}} alignItems={'flex-start'}>
          <img src={tmpPNG} alt='img' width={'50%'}/>
          <Stack width={'35%'} spacing={{xs:'0.5rem', sm:'1.5rem',md: '3rem'}} pt={{xs:'10%', sm: '15%', md:'20%'}}>
            <Typography sx={{...contentTitleStyle(theme)}}>日本初の伝統製品</Typography>
            <Typography sx={{...contentMessageStyle(theme)}}>ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー</Typography>
          </Stack>
        </Stack>


      </Stack>

    
      
    </Box>
  )
}

export default Works
