import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Stack, Theme, useTheme } from '@mui/material';
import ApplyButton from './Button/applyButton';


const mainTitleStyle = (theme: Theme) => ({
  fontFamily: 'Noto Sans JP',
  color: 'white',
  fontSize: {
    xs: '36px',
    sm: '48px',
    md: '64px',
  },
});

const messageStyle = (theme: Theme) => ({
  fontFamily: 'Noto Sans JP',
  color: 'white',
  fontSize: {
    xs: '16px',
    sm: '18px',
    md: '20px',
  },
});

const Top = () => {
  const theme = useTheme();

  return (
      <Box
      bgcolor={'skyblue'}
      sx={{
        width: '100%'
      }}>
      <Stack alignItems={'center'} 
      spacing={{
        xs: '1.5rem',
        sm: '2rem',
        md: '3rem'
      }}
      sx={{
        px:'0.5rem',
        pt:  {
          xs: '10rem',
          sm: '15rem',
          md: '20rem'
        },
        pb: {
          xs: '5rem',
          sm: '7.5rem',
          md: '10rem'
        }
      }}>
        <Typography sx={{...mainTitleStyle(theme)}}>
          世界へ、その一歩を。
        </Typography>
        <Stack spacing={'1rem'} alignItems={'center'}>
          <Typography sx={{...messageStyle(theme)}}>
            日本企業の海外クラウドファンディングを、
          </Typography>
          <Typography sx={{...messageStyle(theme)}}>
            私たちが徹底的にサポートします。
          </Typography>
        </Stack>
        <ApplyButton color='blue'/>
      </Stack>
    </Box>
  )
}

export default Top
