import { Stack, Typography, TextField } from '@mui/material'
import React, {useState, ChangeEvent} from 'react'
import { titleStyle, subTitleStyle, contentStyle } from '../components/style/style';
import { useIsMobile } from '../components/isMobile';
import ApplyButton from '../components/Button/applyButton';
import Apply from '../components/apply';


interface Props{
  isMobile: boolean
}

const ApplyPage = () => {
  return (
    <Stack alignItems={'center'} bgcolor={'#F6F8FA'}>
      <Apply />
    </Stack>
  )

}

export default ApplyPage

