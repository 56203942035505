import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Box, CircularProgress, Stack, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';
import Modal from '@mui/material/Modal';

interface Props {
  title: string,
  isDisable: boolean,
  open: boolean,
  onClick : ()=>void;
  onClose : ()=>void;
  isLoading?: boolean;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '1px solid gray',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};


const SendButton: React.FC<Props> = ({ title, isDisable=false, open=false, onClick, onClose, isLoading}) => {
  const baseColor = '#383E86';
  const disabledColor = '#A0A0A0'; 

  return (
    <div>
      <Button
        fullWidth 
        disabled={isDisable}
        onClick={onClick}
        sx={{
          bgcolor: '#383E86', 
          color: 'white', 
          fontSize:{
            xs: '16px',
            sm: '20px',
            md: '25px'
          }, 
          borderRadius:2,
          '&:hover': {
            bgcolor: isDisable ? disabledColor : lighten(baseColor, 0.2),
          },
          '&:disabled': {
            bgcolor: disabledColor,
            color: 'white', // 無効時のテキスト色
          },
        }}
      >
        {title}
      </Button>
      <Modal
        open={open}
        onClose={onClose}
      >
        <Stack sx={{ ...style }} p={5} alignItems={'center'} justifyContent={'center'}>
          {isLoading ? 
          <CircularProgress/>
          :
          <Typography>Success!</Typography>
          }
        </Stack>
      </Modal>
    </div>
      

  )
}

export default SendButton
