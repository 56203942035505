import {Box, Divider, Stack, Typography, useTheme} from '@mui/material';
import React from 'react'
import { categoryStyle, categorySubStyle, mainTitleStyle, messageStyle } from './style/style';
import ApplyButton from './Button/applyButton';

const Contact = () => {
  const theme = useTheme();

  return (
    <Box id='contact' 
    sx={{
      background: 'linear-gradient(to right, #43CEA2 0%, #185A9D 100%)',
      width: '100%'
    }}
    >
      <Stack alignItems={'flex-start'} px={'15rem'} py={'5rem'}
      sx={{
        px: {
          xs: '1.5rem',
          sm: '5rem',
          md: '15rem'
        },
        py: {
          xs: '2rem',
          sm: '3rem',
          md: '5rem'
        }
      }}>
        <Typography sx={{...categoryStyle(theme)}}>CONTACT ――――――――――</Typography>
        <Typography
    
        sx={{...categorySubStyle(theme),
          mb: {
          xs: '1rem',
          sm: '2rem',
          md: '3rem'
        }}}>無料のご相談</Typography>

        <Typography
        sx={{...mainTitleStyle(theme), }}>「私たちと一緒に</Typography>
        <Typography sx={{...mainTitleStyle(theme), 
          mb: {
            xs: '1rem',
            sm: '2rem',
            md: '3rem'
          }}
        }>海外へ進出しませんか。」</Typography>

        <Typography sx={{...messageStyle(theme)}}>私たちプロフェッショナルなスタッフが海外進出をご支援します。</Typography>
        <Typography 
        sx={{...messageStyle(theme), 
          mb: {
              xs: '1rem',
              sm: '2rem',
              md: '3rem'
            }}
        }>まずは無料のご相談から、お気軽にお問い合わせください。</Typography>
        <ApplyButton/>
      </Stack>
    </Box>
  )
}

export default Contact
