import React from 'react'
import {Box, Divider, Stack, Typography, useMediaQuery} from '@mui/material';
import { CSSProperties } from 'react';
import { categoryStyle, categorySubStyle, mainTitleStyle, messageStyle } from './style/style';
import { useTheme } from '@mui/material/styles';


const About = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box id='about' bgcolor={'#383E86'} width={'100%'}>
      <Stack alignItems={'flex-start'}
      sx={{
        px: {
          xs: '1.5rem',
          sm: '5rem',
          md: '10rem'
        },
        py: {
          xs: '2rem',
          sm: '5rem',
          md: '8rem'
        }
      }}>
        <Typography sx={{...categoryStyle(theme)}}>ABOUT ――――――――――</Typography>
        <Typography
        sx={{...categorySubStyle(theme),
          mb: {
            xs: '1rem',
            sm: '2rem',
            md: '3rem'
          }

        }}>私たちのミッション</Typography>
        <Typography
        sx={{...mainTitleStyle(theme),
          mb: {
            xs: '1rem',
            sm: '2rem',
            md: '3rem'
          }
        }}>「日本の技術を、世界へ届けたい」</Typography>
        <Typography sx={{...messageStyle(theme)}}>私たちRE-IDEAはあなたの技術を世界へ届けるお手伝いをする企業です。</Typography>
        <Typography sx={{...messageStyle(theme)}}>中には実際のクラウドファンディングを成功させた経験がある者や、</Typography>
        <Typography sx={{...messageStyle(theme)}}>海外在住経験があるため語学が堪能な者、</Typography>
        <Typography sx={{...messageStyle(theme)}}>実際に海外クラファンの支援事業をしている者がおり、</Typography>
        <Typography
        sx={{...messageStyle(theme),
          mb: {
            xs: '2rem',
            sm: '2.5rem',
            md: '3rem'
          }
        }}>これらの問題に柔軟に対応することができます。</Typography>
        <Typography
         sx={{...messageStyle(theme)}}>ぜひ、私たちを海外クラファンを成功させましょう！</Typography>

      </Stack>
  </Box>
  )
}

export default About
