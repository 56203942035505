import { Stack, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, useTheme } from '@mui/material'
import React, {useState, ChangeEvent, useEffect} from 'react'
import { applySubStyle, applyContentStyle, applyTitleStyle} from '../components/style/style';
import ApplyTextField from './applyTextField';
import SendButton from './Button/sendButton';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Apply = () => {
  const theme = useTheme();

  const [selectedPlan, setSelectedPlan] = useState('');
  const [userSei, setUserSei] = useState('');
  const [userMei, setUserMei] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [contents, setContents] = useState('');
  const [others, setOthers] = useState('');
  const [date1, setDate1] = useState<Dayjs|null>(null);
  const [date2, setDate2] = useState<Dayjs|null>(null);
  const [date3, setDate3] = useState<Dayjs|null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setIsLoading] = useState(false);


  const checkFormValidity = (): void => {
    const isValid = !!(
      selectedPlan &&
      selectedPlan !== '' &&
      userSei &&
      userSei.trim() !== '' &&
      userMei &&
      userMei.trim() !== '' &&
      phoneNumber &&
      phoneNumber.trim() !== '' &&
      email &&
      email.trim() !== '' &&
      contents &&
      contents.trim() !== '' &&
      date1 !== null &&
      date2 !== null &&
      date3 !== null &&
      !(date1.isSame(date2, 'day') || date1.isSame(date3, 'day') || date2.isSame(date3, 'day'))
    
    );
    setIsFormValid(isValid);
  };

  const changeSlectedPlan = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPlan(event.target.value);
  };
  const changeUserSei = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserSei(e.target.value);   
  };
  const changeUserMei = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserMei(e.target.value);
  };
  const changePhoneNumber = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPhoneNumber(e.target.value);
  };
  const changeEmail = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(e.target.value);
  };
  const changeContents = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContents(e.target.value);
  };
  const changeOthers = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOthers(e.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  
  const handleSendClick = () => {
    // ここで実際の送信処理を行う
    setOpen(true);
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false);
      // フォームをリセット
      setSelectedPlan('');
      setUserSei('');
      setUserMei('');
      setPhoneNumber('');
      setEmail('');
      setContents('');
      setOthers('');
      setDate1(null);
      setDate2(null);
      setDate3(null);
    }, 2500)

    // fetch(`https://api.re-idea.jp/ideas/simple/`, {
    //   method: `GET`,
    // }).then(response => {response.json(); setIsLoading(false)})
    //   .then(data => console.log('contact success:', data))
    //   .catch((error) => console.log('error:', error))
  };

  useEffect(() => {
    checkFormValidity();
  }, [selectedPlan, userSei, userMei, phoneNumber, email, contents, others, date1, date2, date3]);

  useEffect(() => {
    const storedValue = localStorage.getItem('editData');
    console.log('nikoniko', storedValue);

    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      console.log(parsedValue)
      setSelectedPlan(parsedValue.selectedPlan);
      setUserSei(parsedValue.userSei);
      setUserMei(parsedValue.userMei);
      setPhoneNumber(parsedValue.phoneNumber);
      setEmail(parsedValue.email);
      setContents(parsedValue.contents);
      setOthers(parsedValue.others);
      setDate1(parsedValue.date1 ? dayjs(parsedValue.date1) : null);
      setDate2(parsedValue.date2 ? dayjs(parsedValue.date2) : null);
      setDate3(parsedValue.date3 ? dayjs(parsedValue.date3) : null);
    }
  }, []);

  useEffect(() => {
    const editData = {
      selectedPlan: selectedPlan,
      userSei: userSei,
      userMei: userMei,
      phoneNumber: phoneNumber,
      email: email,
      contents: contents,
      others: others,
      date1: date1,
      date2: date2,
      date3: date3,
    };
    console.log('get', editData)
    if(selectedPlan || userSei || userMei || phoneNumber || email || contents || others || date1 || date2 || date3) {
      localStorage.setItem('editData', JSON.stringify(editData));
      console.log(JSON.stringify(editData))
    }
  }, [selectedPlan, userSei, userMei, phoneNumber, email, contents, others, date1, date2, date3]);
  


  return (
    <Stack width={'100%'} alignItems={'center'} py={{xs:'6rem', sm: '8rem', md:'10rem'}} spacing={5}>
      <Stack width={'80%'} alignItems={'center'} spacing={3}>
        <Typography sx={{...applyTitleStyle(theme)}}>お申し込み</Typography>
        <Typography sx={{...applyContentStyle(theme)}}>お申し込みの場合は、オンライン会議にてお打ち合わせさせていただいておりますので、ご希望の場合はページ下部のお打ち合わせご希望日時より、ご都合のつく日をご入力ください。</Typography>
      </Stack>
      <Stack width={'80%'} spacing={3}>
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={1}>
            <Typography sx={{ ...applySubStyle(theme) }}>ご契約プラン</Typography>
            <Typography sx={{ ...applySubStyle(theme), color: 'red' }}>*</Typography>
          </Stack>
            <FormControl component="fieldset">
              <RadioGroup aria-label="plan" name="plan" value={selectedPlan} onChange={changeSlectedPlan}>
                <Stack direction={'row'} spacing={{xs: 2, sm: 4, md: 5}}>
                  <FormControlLabel value="basic" control={<Radio />} label="ベーシックプラン"/>
                  <FormControlLabel value="premium" control={<Radio />} label="プレミアプラン" />
                </Stack>
              </RadioGroup>
            </FormControl>
        </Stack>
        <ApplyTextField
        title="姓"
        placeholder="例：細見"
        name='userSei'
        value={userSei}
        onChange={changeUserSei}
        isRequired={true}
        type='text'
        theme={theme}
        />
        <ApplyTextField
        title="名"
        placeholder="例：元気"
        name='userMei'
        value={userMei}
        onChange={changeUserMei}
        isRequired={true}
        theme={theme}
        type='text'
        />
        <ApplyTextField
        title="電話番号"
        placeholder="0120-123-456"
        name='phoneNumber'
        value={phoneNumber} 
        onChange={changePhoneNumber}
        isRequired={true}
        type={'tel'}
        theme={theme}

        />
        <ApplyTextField
        title="Eメール"
        placeholder="⚪︎⚪︎⚪︎⚪︎⚪︎⚪︎@gmail.com"
        name='email'
        value={email} 
        onChange={changeEmail}
        isRequired={true}
        type='email'
        theme={theme}

        />
        <ApplyTextField
        title="掲載内容"
        placeholder="海外クラウドファンディングサイトに掲載したい内容を簡潔にご記入ください"
        value={contents} 
        onChange={changeContents}
        isRequired={true}
        type='text'
        rows={3}
        theme={theme}

        />
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={1}>
            <Typography sx={{ ...applySubStyle(theme) }}>打ち合わせ希望日時</Typography>
            <Typography sx={{ ...applySubStyle(theme), color: 'red' }}>*</Typography>
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DatePicker 
              label="第1希望"
              value={date1}
              onChange={(newValue) => setDate1(newValue)}
              />
              <DatePicker 
              label="第2希望"
              value={date2}
              onChange={(newValue) => setDate2(newValue)}
              />
              <DatePicker 
              label="第3希望"
              value={date3}
              onChange={(newValue) => setDate3(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Stack>
        <ApplyTextField
        title="その他"
        placeholder="ご質問、ご希望等ございましたらご記入ください"
        value={others} 
        onChange={changeOthers}
        isRequired={false}
        type='text'
        rows={3}
        theme={theme}

        />
      </Stack>

      <Stack width={{
        xs: '50%',
        sm: '40%',
        md: '30%'
      }}>
          <SendButton 
          title='送信' 
          isDisable={!isFormValid} 
          open={open} 
          onClick={handleSendClick} 
          onClose={handleClose} 
          isLoading={loading}
          />
        </Stack>
    </Stack>
  )
}

export default Apply
