import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/material';
import { lighten } from '@mui/material/styles';

interface Props {
  color?: string;
}



const ApplyButton = ({color='linear-gradient(to right, #43cea2, #185a9d)'}: Props) => {
  
  return (
    <Button
      onClick={() => {window.location.href="/apply"}}
      sx={{
        width: 'auto',
        background: color,
        color: 'white',
        fontSize:{
          xs: '16px',
          sm: '20px',
          md: '25px'
        },
        borderRadius: '50px',
        padding: {
          xs: '8px 16px',
          sm: '9px 18px',
          md: '10px 20px'
        },
        border: '1px solid white',
        textTransform: 'none',
        fontWeight: 'normal',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          background: 'linear-gradient(to right, #3cb9a0, #164e8d)',
          boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
        },
      }}
    >
      お問い合わせ
    </Button>
  )
}

export default ApplyButton
