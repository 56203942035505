import React from 'react';
import { Routes, Route } from "react-router-dom";
import HomePage from '../pages/home-page';
import ApplyPage from '../pages/apply-page';
import Footer from '../components/footer';
import Header from '../components/header_new';
import {Box, Button, Stack, Typography, darken, lighten, useMediaQuery, useTheme } from '@mui/material';
import HeaderSP from '../components/headerSP';


const Router = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  console.log(theme.breakpoints)
  
  return (
    <div id='router'>
      {isMobile ? <HeaderSP/> : <Header/>}
      <Routes>
        <Route path="/" element={<HomePage isMobile={isMobile} />} />
        <Route path="/apply" element={<ApplyPage/>} />
      </Routes>
      <Footer isMobile={isMobile}/>
    </div>
  );
};

export default Router;