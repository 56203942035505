import React from 'react'
import {Stack, Link} from '@mui/material';
import XIcon from '../img/sns/x_white_icon.png';
import NoteIcon from '../img/sns/note_white_icon.png'
import FacebookIcon from '../img/sns/fb_white_icon.png';
import InstagramIcon from '../img/sns/ig_white_icon.png';
import { useIsMobile } from './isMobile';





const SnsLink = () => {

  return (
      <Stack 
      sx={{
        position: 'fixed',
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        zIndex: 10,
        right: 0,
      }}
      spacing={'1rem'}
      alignItems={'center'}
      py={'1rem'}
      px={'0.5rem'}
      bgcolor={'rgba(56, 62, 134, 0.65)'}>

        <Link href='http://www.facebook.com/share/WPmE2sgMqvUiL5Dg'>
            <img src={FacebookIcon} alt='img' width={'20px'}/>
        </Link>
        <Link href='http://www.instagram.com/re_idea.official'>
          <img src={InstagramIcon} alt='img' width={'20px'}/>

        </Link>
        <Link href='http://x.com/reidea_official'>
          <img src={XIcon} alt='img' width={'20px'}/>
        </Link>
        <Link href='https://note.com/re_idea'>
          <img src={NoteIcon} alt='img' width={'40px'}/>
        </Link>
      </Stack>
  )
}

export default SnsLink
