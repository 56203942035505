import React from 'react'
import {Stack, Typography, Box, useTheme, useMediaQuery} from '@mui/material';
import { categoryStyle, categorySubStyle, mainTitleStyle, messageStyle } from './style/style';
import meritPNG from  '../img/merit.png'
import BannerCarousel from './Carousel/BannerCarousel';
import MeritCarouselCard from './meritCarouselCard';


const meritCards = [
  {
    number: '01',
    title: '巨大な市場規模',
    content: 'Kickstarterは約2,300億円、Indiegogoは約'
  },
  {
    number: '02',
    title: '高い成長率',
    content: '市場の拡大に伴い、急速な成長が見込まれます'
  },
  {
    number: '03',
    title: '技術革新の機会',
    content: '新しい技術の導入により、さらなる発展が期待されます'
  }
];


const Merit = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const renderMeritCard = (card: any) => (
    <MeritCarouselCard
      number={card.number}
      title={card.title}
      content={card.content}
    />
  );
  const carouselItems = meritCards.map(renderMeritCard);



  return (
    <Box id='merit' bgcolor={'#F5FBFE'} width={'100%'}>
      <Stack py={'5rem'} direction={'row'}
      sx={{
        py: {
          xs: '2rem',
          sm: '3rem',
          md: '5rem'
        },
        gap: {
          xs: '0.5rem',
          sm: '2rem',
          md: '4rem', 
          lg: '8rem',
          xl: '10rem'
        }
      }}
      >
        <img src={meritPNG} alt='png' width={'40%'}/>
        <Stack sx={{
          gap: {
            xs: '0.5rem',
            sm: '1rem',
            md: '2.5rem', 
            lg: '3rem',
            xl: '4rem'           }
        }}>
          <Stack>
            <Typography sx={{...categoryStyle(theme), color:'#383E86'}}>MERIT ――――――――――</Typography>
            <Typography
            sx={{...categorySubStyle(theme), color:'#383E86',
              mb: {
                xs: '1rem',
                sm: '2rem',
                md: '3rem'
              }
            }}>海外クラウドファンディングの魅力</Typography>
            <Typography sx={{...mainTitleStyle(theme), color:'#383E86'}}>「世界中の顧客にリーチし、</Typography>
            <Typography sx={{...mainTitleStyle(theme), color:'#383E86',
              mb: {
                xs: '1rem',
                sm: '2rem',
                md: '3rem'
              }
            }}>国際的な認知度を高める。」</Typography>
          </Stack>
          {carouselItems}
          {/* <BannerCarousel isMobile={isMobile} delay={0} 　img_srcs={carouselItems}/> */}
        </Stack>
      </Stack>
    </Box>
  )
}

export default Merit;
