import React from 'react'
import { Stack, Typography } from '@mui/material';
import About from '../components/about';
import Merit from '../components/merit';
import Service from '../components/service_new';
import Works from '../components/works'
import Contact from '../components/contact';
import SnsLink from '../components/snsLink';
import BannerCarousel from '../components/Carousel/BannerCarousel';

import ScrollButton from '../components/Button/scrollButton';
import Top from '../components/top';

interface Props {
  isMobile : boolean;
}


const HomePage = ({isMobile}: Props) => {

  return (
    <Stack alignItems={'center'} bgcolor={'white'}>
      {isMobile && <ScrollButton/>}
      {!isMobile && <SnsLink/>}
      <Top/>
      <About />
      <Service />
      <Merit />
      <Works />
      <Contact />
    </Stack>
  )
}

export default HomePage;
