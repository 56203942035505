import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Stack, Link as PageLink, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-scroll';
import HeaderSP from './headerSP';



const StyledAppBar = styled(AppBar)({
  backgroundColor: 'transparent',
  boxShadow: 'none',
});

const NavButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  color: 'white',
  fontFamily: 'Jost',
  WebkitTextStroke: '0.4px black',
  
  [theme.breakpoints.up('xs')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '18px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '22px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '24px',
  },
}));


const App: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display={'flex'}>
      <StyledAppBar position="fixed" 
      sx={{
        py: {
          xs: '1rem',
          sm: '1.5rem',
          md: '2rem',  
          lg: '2.5rem',
          xl: '3rem'
        },
        px: {
          xs: '0.5rem',
          sm: '0.75rem',
          md: '1rem', 
          lg: '1.25rem',
          xl: '1.5rem'
        }
      }}>
        <Toolbar>
          <Stack width={'100%'} direction={'row'} justifyContent={'space-between'}>
            <PageLink href="/" style={{textDecoration: 'none'}}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: 'Poppins', fontWeight: 'bold', color:'white', WebkitTextStroke: '0.4px black', 
              fontSize: {
                xs: '24px',  
                sm: '32px',  
                md: '36px',  
                lg: '40px',  
              },}}
              >
                RE-IDEA
              </Typography>
            </PageLink>
            <Stack direction={'row'}>
              <NavButton>
                <Link to="about" smooth={true} duration={500} >
                ABOUT
                </Link>
              </NavButton>
              <NavButton>
                <Link to="service" smooth={true} duration={500}>
                  SERVICE
                </Link>
              </NavButton>
              <NavButton>
                <Link to="merit" smooth={true} duration={500}>
                MERIT
                </Link>
              </NavButton>
              <NavButton>
                <Link to="works" smooth={true} duration={500}>
                  WORKS
                </Link>
              </NavButton>
              <NavButton>
                <Link to="contact" smooth={true} duration={500}>
                  CONTACT
                </Link>
              </NavButton>
            </Stack>
          </Stack>
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
};

export default App;