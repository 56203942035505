import { CSSProperties } from 'react';
import { Theme } from '@mui/material/styles';



export const titleStyle = (isMobile: boolean): CSSProperties  => ({
  fontSize: isMobile? '25px':'40px',
  fontWeight: 600,
  color: '#383E86',
});

export const subTitleStyle = (isMobile: boolean): CSSProperties  => ({
  fontSize: isMobile? '20px':'30px',
  fontWeight: 550,
});

export const contentStyle = (isMobile: boolean): CSSProperties  => ({
  fontSize: isMobile? '16px':'25px',
  fontWeight: 400,
});

export const subStyle = (isMobile: boolean): CSSProperties  => ({
  fontSize: isMobile? '14px':'20px',
  // fontWeight:isMobile?400 : 550,
});

export const categoryStyle = (theme: Theme) => ({
  fontFamily: 'Jost',
  fontWeight: 'bold',
  color: 'white',
  fontSize: {
    xs: '18px',
    sm: '20px',
    md: '22px',
    lg: '24px',
  },
});

export const categorySubStyle = (theme: Theme) => ({
  fontFamily: 'Noto Sans JP',
  fontWeight: 'bold',
  color: 'white',
  fontSize: {
    xs: '14px',
    sm: '16px',
    md: '17px',
    lg: '18px',
  },
});

export const mainTitleStyle = (theme: Theme) => ({
  fontFamily: 'Noto Sans JP',
  fontWeight: 'medium',
  color: 'white',
  fontSize: {
    xs: '25px',
    sm: '30px',
    md: '34px',
    lg: '42px',
  },
});

export const messageStyle = (theme: Theme) => ({
  fontFamily: 'Noto Sans JP',
  fontWeight: 'medium',
  color: 'white',
  fontSize: {
    xs: '14px',
    sm: '16px',
    md: '17px',
    lg: '18px',
  },
});


// ApplyPageで利用するスタイル

export const applyTitleStyle = (theme: Theme) => ({
  fontSize: {
    xs:'25px',
    sm:'35px',
    md: '40px'
  },
  fontWeight: 600,
  color: '#383E86',
});



export const applyContentStyle = (theme: Theme)=> ({
  fontSize: {
    xs:'16px',
    sm:'20px',
    md: '25px'
  },
  fontWeight: 400,
});

export const applySubStyle = (theme: Theme) => ({
  fontSize: {
    xs:'14px',
    sm:'16px',
    md: '20px'
  },
});
