import React from 'react';
import {Box, Button, Stack, Typography, darken, lighten, useTheme, Theme} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { titleStyle, contentStyle, subStyle } from '../components/style/style';
import ApplyButton from './Button/applyButton';
import { useIsMobile } from './isMobile';
import { categoryStyle, categorySubStyle, mainTitleStyle, messageStyle } from '../components/style/style';
import servicePNG from '../img/service.png'


const contentTitleStyle = (theme: Theme) => ({
  fontFamily: 'Noto Sans JP',
  fontWeight: 'medium',
  color: '#383E86',
  fontSize: {
    xs: '20px',  // エクストラスモール画面用
    sm: '24px',  // スモール画面用
    md: '32px',  // ミディアム画面用
  },
});

const contentMessageStyle = (theme: Theme) => ({
  fontFamily: 'Noto Sans JP',
  fontWeight: 'medium',
  color: 'black',
  fontSize: {
    xs: '14px',  // エクストラスモール画面用
    sm: '16px',  // スモール画面用
    md: '18px',  // ミディアム画面用
    lg: '20px',  // ラージ画面用
  },
});

const Service = () => {
  const theme = useTheme();
  return (
    <Box id='service' width={'100%'}>
      <Stack alignItems={'centers'} 
      spacing={{
        xs: '2rem',
        sm: '3rem',
        md: '5rem'
      }}
      sx={{
        px: {
          xs: '1.5rem',
          sm: '5rem',
          md: '10rem'
        },
        py: {
          xs: '2rem',
          sm: '5rem',
          md: '8rem'
        }
      }}>
        <Stack>
          <Typography sx={{...categoryStyle(theme), color:'#383E86'}}>SERVICE ――――――――――</Typography>
          <Typography
          sx={{...categorySubStyle(theme), color:'#383E86', 
            mb: {
            xs: '1rem',
            sm: '2rem',
            md: '3rem'
          }}}>私たちにできること</Typography>
          <Typography sx={{...mainTitleStyle(theme), color:'#383E86'}}>「最小限のリスクで、</Typography>
          <Typography sx={{...mainTitleStyle(theme), color:'#383E86', mb: {
            xs: '1rem',
            sm: '2rem',
            md: '3rem'
          }}}>最大限のサポートを。」</Typography>
          <Typography sx={{...messageStyle(theme), color:'#383E86'}}>少額の初期費用で海外クラウドファンディングをお手伝いいたします。</Typography>
          <Typography sx={{...messageStyle(theme), color:'#383E86'}}>プロジェクト終了まで責任を持って手厚くサポートいたします。</Typography>
        </Stack>

        <Stack direction={'row'} spacing={{xs:'1rem', sm: '4rem', md: '7rem'}} alignItems={'center'}>
          <Stack alignItems={'center'} spacing={'2rem'}>
            <Typography sx={{...contentTitleStyle(theme)}}>「計算文の構成・翻訳」</Typography>
            <Box>
              <Typography sx={{...contentMessageStyle(theme)}}>ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー</Typography>
              <Typography sx={{...contentMessageStyle(theme)}}>ダミーダミーダミーダミーダミーダミーダミーダミー</Typography>
            </Box>
          </Stack>
        <img src={servicePNG} alt='png' width={'50%'}/>
      </Stack>

      <Stack direction={'row'} spacing={{xs:'1rem', sm: '4rem', md: '7rem'}} alignItems={'center'}>
        <img src={servicePNG} alt='png' width={'50%'}/>
        <Stack alignItems={'center'} spacing={'2rem'}>
          <Typography sx={{...contentTitleStyle(theme)}}>「運営・支援者様との連絡」</Typography>
          <Box>
            <Typography sx={{...contentMessageStyle(theme)}}>ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー</Typography>
          </Box>
        </Stack>
      </Stack>
      <Stack direction={'row'} spacing={{xs:'1rem', sm: '4rem', md: '7rem'}} alignItems={'center'}>
          <Stack alignItems={'center'} spacing={'2rem'}>
            <Typography sx={{...contentTitleStyle(theme)}}>「海外発送のご相談」</Typography>
            <Box>
              <Typography sx={{...contentMessageStyle(theme)}}>ダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミーダミー</Typography>
            </Box>
          </Stack>
        <img src={servicePNG} alt='png' width={'50%'}/>
      </Stack>

      

      </Stack>

      
    </Box>
  )
}

export default Service
